.main-project {
  margin: 20vh auto;
  padding: 0vh;
  justify-content: center;
  display: flex;
  width: 100%;
  height: auto;

  background-color: #ffffff;
}
.text-box {
  height: 50vh;
  width: auto;
  max-width: 400px;
  max-height: 600px;

  background-color: rgb(255, 255, 255);
  text-align: justify;
  overflow-y: auto;
  resize: horizontal;
}
.project-teaser-picture {
  padding: 5px;
  margin-left: 0px;
  height: 50vh;
  width: 50vh;
  max-width: 600px;
  max-height: 600px;
  display: flex;
  cursor: zoom-in;
}
.project-carousel {
  height: 50vh;
  width: 50vh;
  max-height: 600px;
  max-width: 600px;
}
.project-carousel:hover {
  opacity: 0.8;
}

.project-carousel li {
  background-color: rgb(180, 180, 180);
  border-radius: 50%;
  margin-top: -30px;
  height: 10px;
  width: 10px;
}
.SRLControls {
  margin-right: 20px;
}
.SRLNextButton {
  margin-right: 25px;

  background-color: white;
  border-radius: 10px;
  opacity: 0.8;
}
.SRLPrevButton {
  background-color: white;
  border-radius: 10px;
  opacity: 0.8;
}
::-webkit-scrollbar {
  width: 0px;
}

.project-h {
  font-weight: 400;
}
.project-sh {
  font-weight: 400;
  font-size: 20px;
}
.project-info {
  font-weight: 100;
  font-size: 15px;
}
.project-fliestext {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}
.project-copyright {
  font-weight: 100;
  font-size: 13px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

@media only screen and (max-width: 600px) {
  .main-project {
    margin-top: 200px;
    margin-left: 0 !important;
    width: 100%;
    display: block;
  }
  .text-box {
    margin: 0 auto;
    margin-top: -80px;
    max-width: 460px;
    min-height: auto;
    margin-bottom: 20px;
  }

  .project-teaser-picture {
    max-height: 300px;
    max-width: 300px;
    padding: 0px;
    flex: auto;
  }
  .project-carousel {
    max-width: 300px;
    max-height: 300px;
  }
  .project-h {
    font-weight: 400;
  }
  .project-sh {
    font-weight: 400;
    font-size: 20px;
  }
  .project-info {
    font-weight: 100;
    font-size: 15px;
  }
  .project-fliestext {
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  }
  .project-copyright {
    font-weight: 100;
    font-size: 13px;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
  }
}
