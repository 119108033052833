.App {
  text-align: center;
  margin: 10px;
  overscroll-behavior: none;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.Main {
  margin: 10px;
  margin-top: 15vh;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
}
.link:hover {
  color: black;
  text-decoration: none;
}
.carousel {
  width: 70vh;
  height: auto;
  max-width: 1000px;
  max-height: 1000px;
  min-width: 40vh;
  min-height: 40vh;
  margin: 0 auto;
}
.dashboard-carousel:hover {
  cursor: crosshair;
}

.carousel-control-next-icon {
  display: none;
}
.carousel-control-prev-icon {
  display: none;
}

.project-frame {
  height: 400px;
  width: 400px;
  flex: 1 0 200px;
  margin: 0 0vh;
  margin-bottom: 100px;

  position: relative;
}

.Project-picture {
  width: 400px;
  height: 400px;
  transition: 200ms ease-in-out;
  z-index: 1;
  position: relative;
}

.texts {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  font-size: 30px;
  width: 400px;
  margin: 0 auto;

  white-space: pre-wrap;
  visibility: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: black;
  text-decoration: none;
  opacity: 1;
}
.project-frame:hover .Project-picture {
  cursor: pointer;
  transform: scale(1.05);
  transition: 200ms ease-in-out;
  opacity: 0.5;
}
.project-frame:hover .texts {
  visibility: visible;
  text-decoration: none;

  animation: fadeIn 5s;
}
.project-frame:focus .texts {
  visibility: visible;
  text-decoration: none;
}
.text-box-about {
  width: 50%;
  height: auto;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-weight: 500;
  background-color: rgb(255, 255, 255);
  text-align: justify;
  overflow-y: auto;
}

@media only screen and (max-width: 600px) {
  .project-frame {
    margin: 0 auto;
    margin-bottom: 50px;
    width: 300px;
    height: 300px;
    z-index: 00;
    background-image: "./images/main0.jpg";
  }

  .Project-picture {
    margin: 0% auto;
    width: 300px;
    height: 300px;
  }

  .texts {
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    flex-wrap: wrap;

    visibility: hidden;
  }

  .dashboard-carousel {
    width: 300px;
    margin: 0 auto;
  }
  .Main {
    margin-top: 120px;
    margin-left: -0px !important;
    width: 100%;
  }
  .carousel {
    min-width: 300px;
    min-height: 300px;
    max-width: 300px;
    max-height: 300px;
  }
  .text-box-about {
    margin: 0 auto;
    margin-top: -80px;

    width: 90%;
  }
}
