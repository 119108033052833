.Navbar {
  background-color: white;
  color: black;
  height: 60px;
  align-items: center;
  z-index: 300;
  position: fixed;
  top: 0px;
  width: 100%;
  font-size: 20px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  padding-top: 25px;
}
.Navbar-logo {
  margin-top: 10px;
  float: left;
  position: relative;
  color: black;
  text-decoration: none;
  margin-left: 30px;
  font-weight: 400;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.Navbar-logo-fullname {
  display: inline-flex;
  white-space: nowrap;
}

.Navbar-logo:hover .Navbar-logo-fullname {
  animation: typing 6.5s steps(150, end), blink-caret 0.75s step-end infinite;
  transition: all 1s linear;
}
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.Navbar-logo:hover {
  color: black;

  text-decoration: none;
}
.Navbar-link {
  margin-top: 10px;
  float: right;
  position: relative;
  text-align: right;
  justify-content: right;
  color: black;
  right: 0px;
  margin-right: 60px;

  flex: right;
  direction: ltr !important;
}
.Navbar-link-color {
  color: black;
  text-decoration: none;
  margin-left: 20px;
}
.navbar-link-color:hover {
  color: black;
  text-decoration: none;
}

.Navbar-link-color:visited {
  color: black;
  text-decoration: none;
}

.Navbar-link:hover {
  color: black;
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  .Navbar {
    direction: rtl !important;

    position: fixed;
    text-align: center;
    padding-top: 15px;
    height: 100px;
    left: 0px;
    padding-left: 0px !important;
  }
  .Navbar-logo {
    float: none;
    position: relative;
    margin: 30px;

    font-size: 20px;
    text-align: center;
    margin-left: 0px !important;
    margin-right: 0px;
    padding-bottom: 20px;
  }
  .Navbar-link {
    float: none;
    font-size: 19px;
    text-align: center;
    overflow: visible;
    margin: 10px auto;
    margin-left: -15px !important;
  }
}
